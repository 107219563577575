import Calendar from "@sellernote/shared/src/sds-v2/components/calendar/Calendar";

import { useSearch } from "../SearchContext";

export default function Etd({ isMobile = false }: { isMobile?: boolean }) {
  const [{ etd }, setSearch] = useSearch();

  const handleEtdSelect = (selectedDate: string) => {
    setSearch({ etd: selectedDate });
  };

  return (
    <Calendar
      className="custom-label-color"
      size="default"
      type="datePicker"
      {...(!isMobile && { inputWidth: "161px" })}
      labelInfo={{
        label: (
          <>
            ETD<span className="required">*</span>
          </>
        ),
      }}
      date={etd}
      setDate={handleEtdSelect}
      getMinDate={() => "tomorrow"}
    />
  );
}
