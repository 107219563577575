import { useMemo, useState } from "react";
import {
  autoUpdate,
  flip,
  useDismiss,
  useFloating,
  useInteractions,
} from "@floating-ui/react";

import { SelectOptionPropsV2 } from "@sellernote/shared/src/headlessComponents/input/useInputSelect";
import { getI18nInstanceForDesignSystem } from "@sellernote/shared/src/i18n/i18nForDesignSystem";
import { noop } from "@sellernote/shared/src/utils/common/etc";
import { useCheckIsMobile } from "@sellernote/shared/src/utils/common/hook";
import ExpandArrowLessFilledIcon from "@sellernote/shared/src/sds-v2/components/svgIcons/ExpandArrowLessFilledIcon";
import ExpandArrowMoreFilledIcon from "@sellernote/shared/src/sds-v2/components/svgIcons/ExpandArrowMoreFilledIcon";
import InfoFilledIcon from "@sellernote/shared/src/sds-v2/components/svgIcons/InfoFilledIcon";

import { COLOR, TEXT_COLOR } from "../../../styles/colors";

import Tooltip from "../../Tooltip";
import Styled from "./index.styles";

export default function SelectOption<T>({
  size,
  labelInfo,
  options,
  onSelect,
  placeholder,
  width,
  selectedOptionValue,
  errorMessage,
  disabled,
  className,
  isExpandIconHidden,
  onOpen,
  onClose,
  optionListPosition = "bottom", // "top" 속성은 QA 대응을 위한 임시 속성 (디자인 시스템에 없음)
}: SelectOptionPropsV2<T>) {
  const { Trans } = getI18nInstanceForDesignSystem();
  const { isMobile } = useCheckIsMobile();

  const [opensOptionList, setOpensOptionList] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    whileElementsMounted: autoUpdate,
    strategy: "fixed",
    open: opensOptionList,
    onOpenChange: setOpensOptionList,
    middleware: isMobile ? undefined : [flip()],
  });

  const dismiss = useDismiss(context, {});

  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

  /** 인풋요소 기준으로 fixed 스타일 계산 */
  const searchResultOptionPosition = (() => {
    const selectOptionElementRect =
      refs.reference.current?.getBoundingClientRect();

    return {
      width: `${selectOptionElementRect?.width || 0}px`,
    };
  })();

  const handleOptionListOpen = () => {
    setOpensOptionList(true);
    onOpen?.();
  };

  const handleOptionListClose = () => {
    setOpensOptionList(false);
    onClose?.();
  };

  const handleOptionListToggle = () => {
    opensOptionList ? handleOptionListClose() : handleOptionListOpen();
  };

  const handleOptionSelect = (value: T) => {
    onSelect(value);
  };

  const selectedOption = useMemo(() => {
    return options.find((v) => v.value === selectedOptionValue);
  }, [options, selectedOptionValue]);

  const arrowColor = useMemo(() => {
    if (disabled) return COLOR.grayScale_400;

    return size === "small" ? COLOR.grayScale_500 : COLOR.grayScale_700;
  }, [disabled, size]);

  return (
    <Styled.container
      width={width}
      size={size}
      labelWidth={labelInfo?.width}
      disabled={disabled}
      className={`${className ? className : ""} select-option`}
    >
      {labelInfo && (
        <label
          className={`${(labelInfo.isLabelHidden && "visually-hidden") ?? ""}`}
        >
          {labelInfo.label}

          {labelInfo.tooltipInfo && (
            <Tooltip
              title={labelInfo.tooltipInfo.title}
              desc={labelInfo.tooltipInfo.desc}
              width={labelInfo.tooltipInfo.width}
              position="bottomLeft"
            >
              <InfoFilledIcon
                width={16}
                height={16}
                color={TEXT_COLOR.black_3}
              />
            </Tooltip>
          )}
        </label>
      )}

      <div className="select-container">
        <Styled.inputWrapper
          onClick={disabled ? noop : handleOptionListToggle}
          size={size}
          isActive={opensOptionList}
          isVisiblePlaceholder={!selectedOption}
          disabled={disabled}
          ref={refs.setReference}
          {...getReferenceProps()}
        >
          <span className="selected-option-value">
            {selectedOption?.label || placeholder || (
              <Trans i18nKey="sds-v2:공통_선택" />
            )}
          </span>

          {opensOptionList && !isExpandIconHidden && (
            <ExpandArrowLessFilledIcon
              width={16}
              height={16}
              color={arrowColor}
              className="icon"
            />
          )}

          {!opensOptionList && !isExpandIconHidden && (
            <ExpandArrowMoreFilledIcon
              width={16}
              height={16}
              color={arrowColor}
              className="icon"
            />
          )}

          {opensOptionList && (
            <Styled.optionList
              onScroll={(e) => e.stopPropagation()}
              onWheel={(e) => e.stopPropagation()}
              size={size}
              disabled={disabled}
              optionListPosition={optionListPosition}
              ref={refs.setFloating}
              {...getFloatingProps()}
              style={floatingStyles}
              searchResultOptionPosition={searchResultOptionPosition}
            >
              {options.map(({ label, value, disabled }, i) => (
                <li
                  key={`select-option-item-${i}`}
                  className={`select-option-item ${disabled ? "disabled" : ""}`}
                  onClick={disabled ? noop : () => handleOptionSelect(value)}
                >
                  {label}
                </li>
              ))}
            </Styled.optionList>
          )}
        </Styled.inputWrapper>

        {errorMessage && <div className="error-message">{errorMessage}</div>}
      </div>
    </Styled.container>
  );
}
