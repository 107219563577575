import styled from "styled-components";

import { COLOR } from "@sellernote/shared/src/sds-v2/styles/colors";
import { setFontStyle } from "@sellernote/shared/src/sds-v2/styles/typography";

const searchBar = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-self: stretch;

  // 디자인 요구사항
  .custom-button-height {
    button {
      height: 48px;
    }
  }
`;

const searchConditionList = styled.div`
  padding: 10px;
  background-color: ${COLOR.grayScale_100};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .custom-label-color label {
    .required {
      ${setFontStyle("Body3")};
      color: ${COLOR.point_300};
    }
  }
`;

const partContainer = styled.div`
  display: flex;
  gap: 8px;

  .calendar {
    flex: 1 0 0;
  }
`;

export default {
  searchBar,
  searchConditionList,
  partContainer,
};
