import { RefObject, useEffect, useRef } from "react";
import { useQueryClient } from "react-query";
import { useAtomValue, useSetAtom } from "jotai";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";

import { SCHEDULE_QUERY_KEY_GEN } from "@sellernote/shared/src/queries/forwarding/SCHEDULE_QUERY";
import {
  OrderOption,
  SortOption,
} from "@sellernote/shared/src/types/forwarding/schedule";
import { checkIsNull } from "@sellernote/shared/src/utils/common/validation";
import Button from "@sellernote/shared/src/sds-v2/components/button/Button";
import useScheduleSearchBarQueryStates from "@sellernote/shipda-kr/src/hooks/forwarding/useScheduleSearchBarQueryStates";
import { COMMON_MAIN_ATOMS } from "@sellernote/shipda-kr/src/jotaiStates/common/main";
import { FORWARDING_COMMON_ATOMS } from "@sellernote/shipda-kr/src/jotaiStates/forwarding/common";

import { FocusInputHandler } from "../../../components/InputSearchWithPortOptions/types";

import { FORWARDING_SCHEDULE_SELECTORS } from "../../../jotaiStates/forwarding/schedule";
import { useSearch } from "../SearchContext";

export default function Search({
  polOptionHandlerListRef,
  podOptionHandlerListRef,
  isMobile = false,
}: {
  polOptionHandlerListRef: RefObject<FocusInputHandler>;
  podOptionHandlerListRef: RefObject<FocusInputHandler>;
  isMobile?: boolean;
}) {
  const { t } = useTranslation("page-forwarding-schedule");

  const searchRef = useRef<HTMLDivElement>(null);

  const { selectedPol, selectedPod } = useAtomValue(
    FORWARDING_SCHEDULE_SELECTORS.SELECTED_PORT_INFO
  );

  const setIsRegistrationButtonVisible = useSetAtom(
    COMMON_MAIN_ATOMS.IS_REGISTRATION_BUTTON_VISIBLE_ATOM
  );

  useEffect(() => {
    if (!isMobile) {
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsRegistrationButtonVisible(!entry.isIntersecting);
      },
      // 수출 배너(54px) + 헤더(48px)
      { rootMargin: "-102px 0px 0px 0px" }
    );

    const search = searchRef.current;

    if (search) {
      observer.observe(search);
    }

    return () => {
      if (search) {
        observer.unobserve(search);
      }
    };
  }, [isMobile, setIsRegistrationButtonVisible]);

  const perPage = useAtomValue(
    FORWARDING_COMMON_ATOMS.FORWARDING_TABLE_PER_PAGE
  );

  const [
    {
      freightType,
      polId: initialPolId,
      polPortGroupId: initialPolPortGroupId,
      podId: initialPodId,
      podPortGroupId: initialPodPortGroupId,
      etd,
      eta,
    },
  ] = useSearch();

  const { polId, podId, polPortGroupId, podPortGroupId } = (() => {
    // 검색옵션을 선택해서 조회하는 경우 선택한 정보로 조회함
    if (selectedPol && selectedPod)
      return {
        polId: selectedPol.id,
        podId: selectedPod?.id,
        polPortGroupId: selectedPol.portGroupId,
        podPortGroupId: selectedPod?.portGroupId,
      };

    // 선택한 값이 없는 경우 URL 기준 초기값으로 조회함
    return {
      polId: initialPolId,
      podId: initialPodId,
      polPortGroupId: initialPolPortGroupId,
      podPortGroupId: initialPodPortGroupId,
    };
  })();

  const [
    {
      minTransitDay,
      maxTransitDay,
      filterPolIds,
      filterPodIds,
      transit,
      linerIdList,
      sort,
      order,
      page,
    },
    setScheduleSearchBarQueryStates,
  ] = useScheduleSearchBarQueryStates();

  const { push, pathname } = useRouter();

  const queryClient = useQueryClient();

  const handleSearch = async () => {
    if (!polId) {
      polOptionHandlerListRef.current?.focusInput();
      return;
    }

    if (!podId) {
      podOptionHandlerListRef.current?.focusInput();
      return;
    }

    if (!etd) {
      return;
    }

    if (pathname === "/") {
      push(
        `/forwarding/schedule?${new URLSearchParams({
          freightType,
          polId: polId.toString(),
          ...(polPortGroupId && { polPortGroupId: polPortGroupId.toString() }),
          ...(podPortGroupId && {
            podPortGroupId: podPortGroupId.toString(),
          }),
          podId: podId.toString(),
          etd,
          ...(eta && { eta }),

          page: "0",

          isSearchBar: "true",

          ...(isMobile && { needsAutoScroll: "true" }),
        }).toString()}`
      );
      return;
    }

    await setScheduleSearchBarQueryStates({
      freightType,
      polId,
      polPortGroupId,
      podId,
      podPortGroupId,
      etd,
      eta,

      page: 0,

      minTransitDay: null,
      maxTransitDay: null,
      filterPodIds: null,
      filterPolIds: null,
      transit: null,
      linerIdList: null,

      sort: SortOption.Cost,
      order: OrderOption.Asc,

      isSearchBar: true,

      needsAutoScroll: true,
    });

    // 초기 조회 조건과 동일한 조건으로 '조회' 버튼을 누른 경우 강제로 fetch 해준다.
    const isInitial =
      checkIsNull(minTransitDay) &&
      checkIsNull(maxTransitDay) &&
      checkIsNull(filterPolIds) &&
      checkIsNull(filterPodIds) &&
      checkIsNull(transit) &&
      checkIsNull(linerIdList) &&
      page === 0 &&
      sort === SortOption.Cost &&
      order === OrderOption.Asc;
    if (isInitial) {
      queryClient.invalidateQueries(
        SCHEDULE_QUERY_KEY_GEN.getScheduleList({
          freightType,
          polId,
          podId,
          etd,
          ...(eta && { eta }),

          perPage,
          page: 0,

          sort: SortOption.Cost,
          order: OrderOption.Asc,
        })
      );
    }
  };

  return (
    <div ref={searchRef}>
      <Button
        {...(isMobile && { width: "100%" })}
        className="search-submit custom-button-height"
        size="large"
        theme={isMobile ? "primary" : "secondary"}
        label={t("page-forwarding-schedule:조회")}
        borderType="filled"
        handleClick={handleSearch}
      />
    </div>
  );
}
