import { FreightTypeOption } from "@sellernote/shared/src/types/forwarding/schedule";
import Tab from "@sellernote/shared/src/sds-v2/components/tab/Tab";

import useFreightType from "../hooks/useFreightType";

export default function FreightTypeForMobile() {
  const { freightType, handleFreightTypeSelect } = useFreightType();

  return (
    <Tab<FreightTypeOption>
      uiType="box"
      direction="horizontal"
      hasBorder
      isStretch
      tabDataList={[
        { tabValue: FreightTypeOption.FCL, title: "FCL" },
        { tabValue: FreightTypeOption.LCL, title: "LCL" },
        { tabValue: FreightTypeOption.Air, title: "AIR" },
      ]}
      selectedTab={freightType}
      onSelect={handleFreightTypeSelect}
    />
  );
}
