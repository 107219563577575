import { useTranslation } from "next-i18next";

import { FreightTypeOption } from "@sellernote/shared/src/types/forwarding/schedule";
import SelectOption from "@sellernote/shared/src/sds-v2/components/form/SelectOption";

import useFreightType from "../hooks/useFreightType";

export default function FreightType() {
  const { t } = useTranslation("page-forwarding-schedule");

  const { freightType, handleFreightTypeSelect } = useFreightType();

  return (
    <SelectOption
      className="custom-label-color"
      width="130px"
      size="default"
      labelInfo={{
        label: (
          <>
            {t("page-forwarding-schedule:운송_방식")}
            <span className="required">*</span>
          </>
        ),
      }}
      selectedOptionValue={freightType}
      onSelect={handleFreightTypeSelect}
      options={[
        { label: "FCL", value: FreightTypeOption.FCL },
        { label: "LCL", value: FreightTypeOption.LCL },
        { label: "AIR", value: FreightTypeOption.Air },
      ]}
    />
  );
}
