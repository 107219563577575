import styled, { css } from "styled-components";

import { SelectOptionSize } from "@sellernote/shared/src/headlessComponents/input/useInputSelect";

import { COLOR, TEXT_COLOR } from "../../styles/colors";
import { setElevationStyle } from "../../styles/elevation";
import { setFontStyle } from "../../styles/typography";

import { LabelInfoPosition } from "./InputSearch";

interface OptionListPositionStyleProps {
  disabled?: boolean;
  size?: SelectOptionSize;
  labelPosition?: LabelInfoPosition;
  width?: string | number;
  optionListPosition: "top" | "bottom";
}

const optionListPositionStyle = css<OptionListPositionStyleProps>`
  position: absolute;
  left: 0;
  max-height: 220px;
  overflow-y: auto;
  ${setElevationStyle("level3")};
  background-color: ${COLOR.wh};
  z-index: 9;

  ${({ optionListPosition }) => {
    if (optionListPosition === "bottom") {
      return css`
        top: 100%;
      `;
    }

    return css`
      bottom: calc(100% + 1px);
    `;
  }};

  ${({ width = "100%", labelPosition }) => {
    if (labelPosition === "left") {
      return css`
        left: auto;
        right: 0;
        width: ${typeof width === "string" ? width : `${width}px`};
      `;
    }

    return css`
      width: ${typeof width === "string" ? width : `${width}px`};
    `;
  }}
`;
export const optionListItemStyle = css<{
  disabled?: boolean;
  size?: SelectOptionSize;
  labelPosition?: LabelInfoPosition;
  width?: string | number;
  optionListPosition: "top" | "bottom";
}>`
  padding: 10px 16px;
  color: ${TEXT_COLOR.black_2};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  ${({ size }) => {
    if (size === "small") {
      return css`
        ${setFontStyle("Body4")};
      `;
    }

    return css`
      ${setFontStyle("Body2")};
    `;
  }};

  &:hover {
    background-color: ${COLOR.primary_50};
  }

  .search-result-label strong {
    color: ${COLOR.primary_600};
  }

  .no-search-results {
    color: ${TEXT_COLOR.black_disabled};
  }
`;

const optionList = styled.ul<{
  disabled?: boolean;
  size?: SelectOptionSize;
  labelPosition?: LabelInfoPosition;
  width?: string | number;
  optionListPosition: "top" | "bottom";
}>`
  ${optionListPositionStyle}

  li {
    ${optionListItemStyle}

    &.disabled {
      color: ${TEXT_COLOR.black_disabled};
      background-color: ${COLOR.grayScale_50};
      cursor: not-allowed;
    }
  }
`;

export type { OptionListPositionStyleProps };

export default {
  optionList,
  optionListPositionStyle,
  optionListItemStyle,
};
